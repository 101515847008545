import styled from "styled-components";

export const EventBodyDiv = styled.div`
  width: var(--width);
  max-width: var(--width);
  padding: 0 var(--outerMargin);
  margin: 25px auto 50px auto;
  --eventColor: ${(props) => (props.color ? props.color : "var(--red)")};
  & > h2 {
    color: var(--eventColor);
    margin-bottom: 50px;
  }
  & > h5 {
    margin-bottom: 35px;
    & a {
      color: var(--black);
    }
  }
  & > div {
    display: flex;
    & > div:first-of-type {
      width: 315px;
      padding-right: 25px;
      & p {
        font-size: 15px;
        line-height: 21px;
        margin: 0;
        letter-spacing: 0.3px;
        & strong {
          display: inline-block;
          width: 75px;
        }
        & a {
          color: var(--black);
        }
      }
      & h5 {
        color: var(--eventColor);
        margin: 20px 0 10px 0;
      }
      & + div {
        --marginLeft: calc(0px - calc(315px + var(--outerMargin)));
        --marginRight: calc(0px - var(--outerMargin));
      }
    }
  }
  & .react-add-to-calendar {
    width: 120px;
    & .react-add-to-calendar__dropdown ul {
      margin: 8px 0 0 0;
      padding: 0;
      list-style-type: none;
      & li {
        display: inline;
        margin: 0 6px;
        white-space: nowrap;

        & a {
          color: var(--text);
          &:hover {
            color: var(--red) !important;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    --outerMargin: 25px;
    & h2 {
      font-size: 24px;
    }
    & > div {
      flex-direction: column;
      align-items: center;
      & > div:first-of-type {
        margin-bottom: 25px;
        padding-right: 0;
      }
    }
  }
  p a.btn_a {
    min-width: 65px;
    min-height: 28px;
    padding: 5px 20px;
    font-size: 12px;
    font-weight: 500;
     width:165px;
    line-height: 1.5;
    text-align:center;
    border: 1px solid #75848c !important;
    display: inline-block;
    margin: 10px 0 !important;    
    color: #75848c !important;
  }
  .btn_a:hover,  .btn_a:focus, .btn_a:active {
    color: #000 !important;
    border: 1px solid #000 !important;

  }
`;
