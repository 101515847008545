import React from "react";
import AddToCalendar from "react-add-to-calendar";
import RichContent from "./../RichContent";
import { H2SmallStrong, H5Small, H5SmallStrong } from "./../Typography";
import { EventBodyDiv } from "./elements";
import MiscSocialSmall from "./../icons/MiscSocialSmall";
import { SmallButton } from "./../Buttons";

const EventBody = ({
  startTime,
  endTime,
  venue,
  venueLocation,
  mainColumn,
  topline,
  subTitle,
  color,
  textColor,
  addToCalendar,
  registerNow,
}) => {
  const tColor=(textColor!=null?textColor:"var(--black)")
  return (
    <EventBodyDiv color={color || "var(--red)"}>
      <H5Small>{topline}</H5Small>
      <H2SmallStrong>{subTitle}</H2SmallStrong> 
      <div>
        <div>
          <p>
            <strong>Starts</strong> {startTime}
          </p>
          {endTime ? (
            <p>
              <strong>Ends</strong> {endTime}
            </p>
          ) : null}
          <p style={{display:'flex',justifyContent: 'inherit'}}>
            <strong>Venue</strong> <span>{venue}</span>
          </p>
          <p>
            <strong /> {venueLocation}
          </p>
          {addToCalendar ? (
            <SmallButton style={{marginTop:'5px'}}>
              <AddToCalendar event={addToCalendar} />
            </SmallButton>
          ) : null}
            <p> {registerNow ? (<a class="btn_a" href={registerNow} target="_blank">{registerNow=='https://dubai.platinumlist.net/event-tickets/88244/quoz-arts-fest'?'Book Tickets':'Register Now'}</a>):null} </p>
          <H5SmallStrong>Share</H5SmallStrong>
          <MiscSocialSmall color={color || "var(--red)"} />
        </div>
        <RichContent content={mainColumn} mainColor={tColor} sliderWidth="1440px"/>
      </div>
    </EventBodyDiv>
  );
};

export default EventBody;
