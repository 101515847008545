import React,{useState,useEffect} from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "./../components/Layout";
import EventHero from "./../components/EventHero";
import EventBody from "./../components/EventBody";
import { RelatedEventsBlock } from "./../components/RecommendationBlock";
import { getTime, getDate } from "./../modules/time";
import { getEventTypeFromID } from "./../modules/eventTypes";
import {GlobalStyle} from "./../globalStyles";
import { BigButton } from "../components/Buttons";

const EventPage = ({ data, pageContext }) => {
  const [relatedEvents, setRelatedEvents] = React.useState(null);
  const [showModal, SetShowModal] = useState(false)
  const {
    id,
    title,
    headerImage,
    bannerGraphic,
    bannerGraphicPortrait,
    headerImageMobile,
    richContent,
    eventStartDateTime,
    eventEndDateTime,
    eventShortDescription,
    eventLocation,
    eventType,
    subtitle,
    callToAction,
    eventUserAddToCalendar,
    showOrHideTitleAndSubtitle,
    hideSubtitle,
    contentTextColor,
    registerNowLink,
    eventLink,
  } = data.craftEntryInterface;



  //  console.log(data.craftEntryInterface);
  const myProgrammes = data.programme1.edges
    .concat(data.programme2.edges)
    .concat(data.programme3.edges);

    

  const myDate = eventEndDateTime
    ? getDate(eventStartDateTime) === getDate(eventEndDateTime) //second one was eventStartDateTime
      ? getDate(eventStartDateTime)
      : getDate(eventStartDateTime) + "–" + getDate(eventEndDateTime)
    : getDate(eventStartDateTime);
  var eventStart =
    getDate(eventStartDateTime) === getDate(eventEndDateTime) || eventEndDateTime==null
      ? getTime(eventStartDateTime)
      : getDate(eventStartDateTime);
  const eventEnd = eventEndDateTime
    ? getDate(eventStartDateTime) === getDate(eventEndDateTime)
      ? getTime(eventEndDateTime)
      : getDate(eventEndDateTime)
    : null;
  // console.log(eventLocation);
  if (eventStart == "0:00 pm") {
    eventStart = "12:00 pm";
  }
  const venueLink =
    eventLocation && eventLocation.length && eventLocation[0] !== null ? (
      eventLocation[0].communityIsThisAnAlserkalProperty ? (
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {eventLocation[0].title}
        </a>
      ) : (
        <Link to={`/community/${eventLocation[0].slug}/`}>
          {eventLocation[0].title}
        </Link>
      )
    ) : null;
  const programmeLink = myProgrammes.length ? (
    <React.Fragment>
      Part of{" "}
      {myProgrammes.length > 1 ? (
        myProgrammes.map((x, index) => (
          <React.Fragment>
            <Link to={`/programme/${x.node.slug}/`} key={index}>
              {x.node.title}
            </Link>
            {index + 1 < myProgrammes.length ? ", " : ""}
          </React.Fragment>
        ))
      ) : (
        <Link to={`/programme/${myProgrammes[0].node.slug}/`}>
          {myProgrammes[0].node.title}
        </Link>
      )}
    </React.Fragment>
  ) : (
    venueLink
  );

  React.useEffect(() => {
    // console.log("in effect loop.");
    const myRelatedEvents = data.events.nodes.filter((x) => x.title !== title);
    let t=getEventTypeFromID(eventType[0]);
    let last = t.charAt(t.length - 1);
    if(last!='s')
    {
      t=t+'s'
    }


    if (myRelatedEvents.length) {
      setRelatedEvents(
        <RelatedEventsBlock
          header={`More ${t}`}
          relatedEvents={myRelatedEvents}
        />
      );
    }
    // console.log(relatedEvents);
  }, [data.events.nodes, eventType, title]);
  // console.log(eventType);
  const thisColor =
    eventType[0] === "exhibitions" || eventType[0] === "guestProjects"
      ? "var(--purple)"
      : "var(--red)";
  contentTextColor
  const c = (contentTextColor != null ? contentTextColor : thisColor);
 
  return (
    <Layout
      backgroundColor={"var(--white)"}
      footerColor={"var(--red)"}
      title={title}
      SEODescription={eventShortDescription} 
    >
      
      <EventHero
        topline={getEventTypeFromID(eventType[0])}
        secondline={myDate}
        titleText={showOrHideTitleAndSubtitle.length > 0 && showOrHideTitleAndSubtitle[0] == 'yes' ? '' : title}
        subTitleText={hideSubtitle.length > 0 && hideSubtitle[0] == 'yes' ? '' : subtitle}
        titleImage={
          headerImage && headerImage.length && headerImage[0].wide && headerImage[0].wide.childImageSharp
             ? (
              <Img
                fixed={headerImage[0].wide.childImageSharp.fixed}
                alt={title}
              />
            ) : (
              bannerGraphic && bannerGraphic.length && bannerGraphic[0].wide && bannerGraphic[0].wide.childImageSharp
             ? (
                <Img
                  fixed={bannerGraphic[0].wide.childImageSharp.fixed}
                  alt={title}
                />
              ) : null
            )
          
        }
        titleMobileImage={
          bannerGraphicPortrait && bannerGraphicPortrait.length && bannerGraphicPortrait[0].tallImage && bannerGraphicPortrait[0].tallImage.childImageSharp
             ? (
              <Img
                fixed={bannerGraphicPortrait[0].tallImage.childImageSharp.fixed}
                alt={title}
              />
            ) : (
              headerImageMobile && headerImageMobile.length && headerImageMobile[0].tallImage && headerImageMobile[0].tallImage.childImageSharp
             ? (
              <Img
                fixed={headerImageMobile[0].tallImage.childImageSharp.fixed}
                alt={title}
              />
            ) : null
            )
          
        }
        callToAction={callToAction}
        linkUrl={eventLink}
        color={c}
      />
      
      <EventBody
        topline={
          <React.Fragment>
            {programmeLink ? (
              <React.Fragment>{programmeLink}</React.Fragment>
            ) : null}
          </React.Fragment>
        }
        subTitle={eventShortDescription}
        startTime={eventStart} 
        endTime={eventEnd}
        venue={venueLink}
        registerNow={registerNowLink}
        venueLocation={
          eventLocation && eventLocation.length && eventLocation[0] !== null
            ? eventLocation[0].communityMemberLocation
            : null
        }
        mainColumn={richContent}
        color={thisColor}
        textColor={contentTextColor}
        addToCalendar={
          eventUserAddToCalendar && {
            title: title,
            description: eventShortDescription || subtitle || "",
            location:
              eventLocation && eventLocation.length
                ? eventLocation[0].communityMemberLocation ||
                eventLocation[0].title
                : "Alserkal",
            startTime: eventStartDateTime,
            endTime: eventEndDateTime,
          }
        }
      />
      
      {relatedEvents}
    </Layout>
  );
};

export default EventPage;

export const pageQuery = graphql`
  query ($id: String!, $myType: String!) {
    craftEntryInterface(id: { eq: $id }) {
      ... on Craft_event_event_Entry {
        id
        title
        eventType
        eventStartDateTime
        eventEndDateTime
        eventUserAddToCalendar
        showOrHideTitleAndSubtitle
        hideSubtitle
        contentTextColor
        registerNowLink
        headerImage {
          ... on Craft_editorialAssets_Asset {
            wide: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1240,height:500,fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        bannerGraphic {
          ... on Craft_editorialAssets_Asset {
            wide: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1240,height:500,fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        bannerGraphicPortrait{ 
          ... on Craft_editorialAssets_Asset {
            tallImage: localFile {
              publicURL
              childImageSharp {
                fixed(height: 550) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_eventsAssets_Asset {
            tallImage: localFile {
              publicURL
              childImageSharp {
                fixed(height: 550) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        headerImageMobile{ 
          ... on Craft_editorialAssets_Asset {
            tallImage: localFile {
              publicURL
              childImageSharp {
                fixed(height: 550) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        eventLink
        eventLocation {
          slug
          title
          ... on Craft_community_communityMember_Entry {
            communityMemberLocation
            communityIsThisAnAlserkalProperty
          }
        }
        subtitle
        eventShortDescription
        callToAction
        richContent {
          ... on Craft_richContent_matterportEmbed_BlockType {
            typeHandle
            matterportUrl
          }
          ... on Craft_richContent_podiantPlayer_BlockType {
            podiantUrl
            typeHandle
          }
          ... on Craft_richContent_anghamiPlayer_BlockType {
            anghamiUrl
            typeHandle
          }
          ... on Craft_richContent_soundcloudPlayer_BlockType {
            soundcloudUrl
            typeHandle
          }
          ... on Craft_richContent_spotifyPlayer_BlockType {
            spotifyUrl
            typeHandle
          }
          ... on Craft_richContent_image_BlockType {
            typeHandle
            imageWithCaptionImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageWithCaptionCaption
          }
          ... on Craft_richContent_slideShow_BlockType {
            typeHandle
            images {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            carouselCaption
          }
          ... on Craft_richContent_richText_BlockType {
            typeHandle
            richText
          }
          ... on Craft_richContent_imageSlider_BlockType {
            typeHandle
            imageSliderImages {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageSliderCaptions {
              captionLine4
              captionLine3
              captionLine2
              captionLine1
            }
          }
          ... on Craft_richContent_videoWithCaption_BlockType {
            typeHandle
            videoWithCaptionCaption
            videoWithCaptionVideoOnTheLeft
            videoWithCaptionUseCredit
            videoWithCaptionUrl
            videoWithCaptionVideo {
              url
            }
          }
          ... on Craft_richContent_photoWithQuote_BlockType {
            typeHandle
            photoWithQuoteImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            photoWithQuoteImageOnTheLeft
            photoWithQuoteImageQuote
            photoWithQuoteImageQuoteAttribution
          }
        }
      }
    }
    events: allCraftEventEventEntry(
      filter: { eventType: { glob: $myType } }
      limit: 4
    ) {
      nodes {
        id
        title
        isStaging
        eventEndDateTime
        eventStartDateTime
        eventShortDescription
        slug
        eventType
        headerImage {
          ... on Craft_editorialAssets_Asset {
            cardImage: localFile {
              publicURL
              childImageSharp {
                fixed(width: 290) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    programme1: allCraftProgrammesProgrammesEntry(
      filter: { programmeEventSelector: { elemMatch: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    programme2: allCraftProgrammesProgrammesEntry(
      filter: {
        programmeHighlightedEventSelector: { elemMatch: { id: { eq: $id } } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    programme3: allCraftProgrammesProgrammesEntry(
      filter: {
        programmeSuperHighlightedEventSelector: {
          elemMatch: { id: { eq: $id } } 
        }
      }
    ) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`;
